import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Express Yourself";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Χορευτικοί ρυθμοί Ζumba, αεροβικοί συνδυασμοί που θα σας γυμνάσουν
          χορεύοντας.
        </P>
        <P>
          Ένα μάθημα εκτόνωσης, ενέργειας και έκφρασης με διαφορετική χορογραφία
          κάθε βδομάδα, όπου μπορούμε να αποκτήσουμε αντοχή και να
          διαχειριστούμε τις έντονες αλλαγές στην ταχύτητα.
        </P>
        <P>Ένα μάθημα για όλους.</P>
      </Section.Main>
    </Layout>
  );
}
